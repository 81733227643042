<template>
  <div class="bigDiv">
    <!-- <img
      @click="towenda"
      class="clickImg"
      src="https://oss.hshwhkj.com/hshcrm/image/2024/12/10/173382038735108114.png"
      alt=""
    /> -->
    <img
      @click="towenda"
      src="https://oss.hshwhkj.com/hshcrm/image/2024/12/10/173382054384441318.gif"
      alt=""
      class="btn"
    />
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      ruleForm: {
        land_link: "", //落地页链接
      },
    };
  },
  created() {
    this.ruleForm.land_link = window.location.href;
  },
  methods: {
    towenda() {
      sessionStorage.setItem("ruleForm", JSON.stringify(this.ruleForm));
      this.$router.push({
        path: "/txFoodCQuestion",
      });
    },
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
.bigDiv {
  width: 100%;
  height: 2500px;
  background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/12/10/173382005528208309.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  .clickImg {
    width: 130px;
    height: 200px;
    position: fixed;
    right: 10px;
    bottom: 110px;
  }
  .btn {
    width: 375px;
    height: 120px;
    object-fit: contain;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 0;
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    height: 2500px;
    background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/12/10/173382005528208309.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin: 0 auto;
    .clickImg {
      width: 130px;
      height: 200px;
      position: fixed;
      right: 10px;
      bottom: 110px;
    }
    .btn {
      width: 750px;
      height: 120px;
      object-fit: contain;
      position: fixed;
      left: 50%;
      transform: translate(-50%);
      bottom: 0;
    }
  }
}
</style>
